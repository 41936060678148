'use client';

import { useEffect, useState } from 'react';

import { RouterProvider } from 'react-router-dom';

import createLegacyRouter from 'legacyRouter';

export default function CatchAllPage() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return <RouterProvider router={createLegacyRouter()} />;
}
